import './polyfills';

import { initMobileNav } from './header';
import { initReviewsCaro } from './home';
import { contactSubmit } from './contact.js';

initMobileNav();

if(document.querySelector('#home_reviews')) {
	initReviewsCaro();
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}
